import { useNode } from '@craftjs/core';
import { forwardRef, PropsWithChildren, useRef } from 'react';

import { CarouselNavigationButtons } from 'components';
import { cn } from 'util/cn';

type LandingSectionProps = PropsWithChildren<{
  title?: string;
  withPadding?: boolean;
  center?: boolean;
  childrenContainerClassName?: string;
}>;

export const LandingSection = forwardRef<HTMLDivElement, LandingSectionProps>(
  ({ title, center = true, withPadding = false, childrenContainerClassName, children }, ref) => {
    const {
      connectors: { connect, drag },
    } = useNode();

    const carouselRef = useRef<HTMLDivElement>(null);

    return (
      <div
        ref={ref || (r => r && connect(drag(r)))}
        className="relative grid gap-4 md:gap-5 w-full pt-5 pb-5 md:pt-8 md:pb-8"
      >
        {title && <h2 className="font-syne text-4 font-bold text-center uppercase">{title}</h2>}
        <div
          ref={carouselRef}
          className={cn(
            `scrollbar-none overflow-x-auto scroll-smooth snap-x snap-mandatory`,

            withPadding
              ? 'flex gap-3 w-full max-w-full px-[max(calc((100vw-var(--n-content-max-width))/2+var(--n-size-2)),var(--n-size-2))] lg:px-[max(calc((100vw-var(--n-content-max-width))/2+var(--n-size-10)),var(--n-size-10))]'
              : 'flex',
            center && 'mx-auto',
            childrenContainerClassName
          )}
        >
          {children}
        </div>
        <CarouselNavigationButtons carouselRef={carouselRef} />
      </div>
    );
  }
);
