import { useNode, UserComponent } from '@craftjs/core';
import React from 'react';

import { ShowcaseCarouselItemSettings } from './ShowcaseCarouselItemSettings';
import { ImageLazy } from 'components';

type ShowcaseCarouselItemProps = {
  children: React.ReactNode;
  imgSrc: string;
  imgBlurSrc?: string;
  imgAlt?: string;
};

export const ShowcaseCarouselItem: UserComponent<ShowcaseCarouselItemProps> = ({
  children,
  imgSrc,
  imgBlurSrc,
  imgAlt = 'Showcase Image',
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={ref => ref && connect(drag(ref))}
      className="flex-none relative snap-center aspect-square w-3/4 max-w-[600px] sm:w-3/5 md:w-[45%] lg:w-1/3"
    >
      <ImageLazy
        src={imgSrc}
        blurSrc={imgBlurSrc}
        alt={imgAlt}
        className="w-full h-full object-cover"
      />
      <div className="right-0 bottom-0 left-0 absolute grid justify-items-start p-5 uppercase font-syne text-3 font-bold leading-none">
        {children}
      </div>
    </div>
  );
};

ShowcaseCarouselItem.craft = {
  related: {
    settings: ShowcaseCarouselItemSettings,
  },
};
