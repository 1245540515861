import { useNode } from '@craftjs/core';

export const ButtonSettings = () => {
  const {
    actions: { setProp },
    variant,
    text,
  } = useNode(node => ({
    variant: node.data.props.variant,
    text: node.data.props.text,
  }));

  return (
    <div className="flex flex-col gap-2">
      <div>Button settings</div>

      <select
        value={variant}
        onChange={e => setProp((props: any) => (props.variant = e.target.value))}
      >
        <option value="default">Default</option>
        <option value="primary">Primary</option>
      </select>

      <input
        type="text"
        value={text}
        onChange={e => setProp((props: any) => (props.text = e.target.value))}
      />
    </div>
  );
};
