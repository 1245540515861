import { useNode, UserComponent } from '@craftjs/core';
import React, { PropsWithChildren } from 'react';

import { ButtonSettings } from './ButtonSettings';
import {
  Button as ButtonComponent,
  NamedLink,
  PrimaryButton as PrimaryButtonComponent,
} from 'components';
import { RouteName } from 'routing/routeConfiguration';

type P = {
  variant?: 'default' | 'primary';
  link?: {
    name: RouteName;
    to?: {
      search: string;
    };
  };
  text?: string;
};

export const Button: UserComponent<P> = ({ variant, link, text = 'Button', ...props }) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const Comp = variant === 'primary' ? PrimaryButtonComponent : ButtonComponent;

  const LinkComp = link
    ? ({ children }) => (
        <NamedLink {...link} ref={ref => link && ref && connect(drag(ref))}>
          {children}
        </NamedLink>
      )
    : React.Fragment;

  return (
    <LinkComp>
      <Comp {...props} ref={ref => !link && ref && connect(drag(ref as any))}>
        {text}
      </Comp>
    </LinkComp>
  );
};

Button.craft = {
  related: {
    settings: ButtonSettings,
  },
};
