import { useNode, UserComponent } from '@craftjs/core';
import { cva, VariantProps } from 'class-variance-authority';

import { ContainerSettings } from './ContainerSettings';
import { cn } from 'util/cn';

const containerVariants = cva('box-border flex', {
  variants: {
    orientation: {
      row: 'flex-row',
      column: 'flex-col',
    },
    gap: {
      '0': 'gap-0',
      px: 'gap-px',
      '.25': 'gap-.25',
      '.5': 'gap-.5',
      '1': 'gap-1',
      '2': 'gap-2',
      '3': 'gap-3',
      '4': 'gap-4',
      '5': 'gap-5',
      '6': 'gap-6',
      '7': 'gap-7',
      '8': 'gap-8',
      '9': 'gap-9',
      '10': 'gap-10',
    },
  },
  defaultVariants: {
    orientation: 'column',
  },
});

export type ContainerProps = React.PropsWithChildren<
  VariantProps<typeof containerVariants> & {
    className?: string;
    container?: boolean;
  }
>;

export const Container: UserComponent<ContainerProps> = ({ className, container, ...props }) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={ref => ref && connect(drag(ref))}
      className={cn(
        containerVariants(props),
        container ? 'max-w-[var(--n-content-max-width)] px-3 lg:px-10 container mx-auto' : '',
        className
      )}
    >
      {props.children}
    </div>
  );
};

Container.craft = {
  related: {
    settings: ContainerSettings,
  },
};
