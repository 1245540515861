import { useNode, UserComponent } from '@craftjs/core';

import { SpacerSettings, SpacingOptions } from './SpacerSettings';

type P = {
  className?: string;
  size: SpacingOptions;
};

export const Spacer: UserComponent<P> = ({ size }) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={ref => ref && connect(drag(ref))}
      style={{
        height: `var(--n-size-${String(size || 1)})`,
      }}
    />
  );
};

Spacer.craft = {
  related: {
    settings: SpacerSettings,
  },
};
