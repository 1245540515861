import { Element, useEditor } from '@craftjs/core';
import { Layers } from '@craftjs/layers';
import * as Accordion from '@radix-ui/react-accordion';
import { decode, encode } from 'js-base64';
import { PanelRightCloseIcon, PanelRightOpenIcon } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from './components/Button';
import { Container } from './components/Container';
import { HeroSection } from './components/HeroSection';
import { LandingSection } from './components/LandingSection';
import { ListingsSection } from './components/ListingsSection';
import { ShowcaseCarousel } from './components/ShowcaseCarousel';
import { ShowcaseCarouselItem } from './components/ShowcaseCarouselItem';
import { Spacer } from './components/Spacer';
import { Text } from './components/Text';
import {
  useEditablePagesQueryParams,
  useGetEditablePageVersions,
  useUpdateEditablePages,
} from './EditablePages.hooks';
import { Layer } from './Layer';
import { IconChevronDown } from 'assets/icons';
import { Button as SecondaryButton, NamedLink, PrimaryButton } from 'components';
import { cn } from 'util/cn';

export const Toolbox = ({ page }: { page: any }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { mutateAsync: updateEditablePages } = useUpdateEditablePages();
  const { data: versionsData } = useGetEditablePageVersions(page.id);
  const [{ version }, setQueryParams] = useEditablePagesQueryParams();

  const { connectors, actions, selected, query } = useEditor(state => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes?.[currentNodeId]?.related?.settings,
      };
    }

    return { selected };
  });

  const pageContentRef = useRef('');
  useEffect(() => {
    if (pageContentRef.current !== page?.content) {
      pageContentRef.current = page?.content;
      if (pageContentRef.current) {
        actions.deserialize(decode(pageContentRef.current));
      }
    }
  }, [actions, page, page?.content]);

  return (
    <div className={cn(`relative bg-neutral-100`, isExpanded ? 'min-w-[350px]' : 'w-0')}>
      <button
        className="absolute -left-6 top-1/2 -translate-y-1/2 text-white bg-black p-1 border-2 border-white rounded-l-md shadow-md"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <PanelRightCloseIcon /> : <PanelRightOpenIcon />}
      </button>
      {isExpanded && (
        <div className="overflow-auto max-h-screen grid gap-2 content-start p-2 ">
          <NamedLink name="EditablePages" params={{ pageId: 'list' }}>
            <SecondaryButton className="w-full">Back to list</SecondaryButton>
          </NamedLink>
          <PrimaryButton
            onClick={() => {
              const editorState = query.serialize();
              updateEditablePages({
                id: page.id,
                name: page.name,
                content: encode(editorState),
              }).then(() => {
                setQueryParams({ version: undefined, edit: true });
              });
            }}
          >
            Save Page
          </PrimaryButton>
          <Accordion.Root type="multiple" defaultValue={['layers']} className="w-full grid gap-2">
            <Accordion.Item value="versions" className="bg-orange-50 p-2 grid gap-2">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>Versions (current: {version || 'Latest'})</span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="grid gap-1">
                  {versionsData?.versions?.map(v => {
                    return (
                      <button
                        key={v}
                        className="block p-.25 bg-orange-100 w-full text-left"
                        onClick={() => {
                          const hasContentChanged = encode(query.serialize()) !== page?.content;

                          if (hasContentChanged) {
                            if (
                              window.confirm(
                                'You have unsaved changes. Are you sure you want to switch versions? Your changes will be lost.'
                              )
                            ) {
                              setQueryParams({ version: v, edit: true });
                            }
                          } else {
                            setQueryParams({ version: v, edit: true });
                          }
                        }}
                      >
                        Version: {v} {versionsData?.deploymentVersions.dev === v && 'DEV'}{' '}
                        {versionsData?.deploymentVersions.prod === v && 'PROD'}
                      </button>
                    );
                  })}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="components" className="bg-green-50 p-2 grid gap-2">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>Components</span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="bg-green-100 grid gap-1 p-2">
                  <button
                    className="bg-green-200 p-1"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={HeroSection}
                          imgSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_compressed.webp"
                          imgBlurSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_blur.webp"
                          canvas
                        />
                      )
                    }
                  >
                    Hero Section
                  </button>
                  <button
                    className="bg-green-200 p-1"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={Button} text="Button" />)
                    }
                  >
                    Button
                  </button>
                  <button
                    className="bg-green-200 p-1"
                    ref={ref => ref && connectors.create(ref, <Element is={Text}>Text</Element>)}
                  >
                    Text
                  </button>
                  <button
                    className="bg-green-200 p-1"
                    ref={ref => ref && connectors.create(ref, <Element is={Spacer} size="1" />)}
                  >
                    Spacer
                  </button>
                  <button
                    className="bg-green-200 p-1"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element is={Container} canvas>
                          <Element is={Text}>Container</Element>
                        </Element>
                      )
                    }
                  >
                    Container
                  </button>
                  <button
                    className="bg-green-200 p-1"
                    ref={ref => ref && connectors.create(ref, <Element is={LandingSection} />)}
                  >
                    Landing Section
                  </button>
                  <button
                    className="bg-green-200 p-1"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element is={ShowcaseCarousel}>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 1
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 1" />
                          </Element>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 2
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 2" />
                          </Element>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 3
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 3" />
                          </Element>
                        </Element>
                      )
                    }
                  >
                    Showcase Carousel
                  </button>
                  <button
                    className="bg-green-200 p-1"
                    ref={ref => ref && connectors.create(ref, <Element is={ListingsSection} />)}
                  >
                    Listings Section
                  </button>
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="layers" className="bg-yellow-50 p-2 grid gap-2">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>Layers</span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <Layers expandRootOnLoad renderLayer={Layer} />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
          {selected?.settings && (
            <div className="bg-yellow-100 p-2">{React.createElement(selected.settings)}</div>
          )}
        </div>
      )}
    </div>
  );
};
