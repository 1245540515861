import { Node, useEditor } from '@craftjs/core';
import { EditableLayerName, useLayer } from '@craftjs/layers';
import _, { cloneDeep } from 'lodash';
import { MonitorSmartphoneIcon, TrashIcon } from 'lucide-react';
import { nanoid } from 'nanoid';

import { Text } from './components/Text';
import { cn } from 'util/cn';

export function Layer({ children }) {
  const layer = useLayer(layer => {
    return {
      hovered: layer.event.hovered,
      expanded: layer.expanded,
    };
  });
  const { actions, query, selected, layerChildIndex } = useEditor((state, query) => {
    const parentNode = Object.values(state.nodes).find(node => node.data.nodes?.includes(layer.id));
    const layerChildIndex = parentNode?.data.nodes?.indexOf(layer.id);
    const selected = state.events.selected;

    return {
      selected,
      layerChildIndex,
      hasChildCanvases: query.node(layer.id).isParentOfTopLevelNodes(),
    };
  });

  const isRoot = layer.id === 'ROOT';
  const node = query.node(layer.id).get();
  const isResponsive =
    node.data.type === Text &&
    (node.data.props.responsiveMinScreen || node.data.props.responsiveMaxScreen);

  return (
    <div ref={ref => ref && layer.connectors.layer(ref)}>
      <div
        className={cn('flex gap-1 items-center', selected?.has(layer.id) && 'bg-yellow-200')}
        ref={ref => ref && layer.connectors.drag(ref)}
      >
        <button
          className={cn('m-.25 p-.25 -mr-.5', isRoot && 'opacity-30')}
          disabled={isRoot}
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this layer?')) {
              actions.delete(layer.id);
            }
          }}
        >
          <TrashIcon className="h-2 w-2" />
        </button>
        {/*
          // TODO: Enable this when we copy the node layers correctly
        <button
          className={cn('m-.25 p-.25', isRoot && 'opacity-30')}
          disabled={isRoot}
          onClick={() => {
            const node = query.node(layer.id);
            const parentId = node.ancestors()?.[0];
            const nodeTree = node.toNodeTree();
            const copiedNodeTree = copyNodeTree(nodeTree);
            actions.addNodeTree(copiedNodeTree, parentId, layerChildIndex);
          }}
        >
          <CopyIcon className="h-2 w-2" />
        </button> */}
        {new Array(layer.depth).fill(0).map((_, index) => (
          <div key={index} className="w-1 border-l" />
        ))}
        <div
          className="flex-1 flex gap-1 items-center"
          ref={ref => ref && layer.connectors.layerHeader(ref)}
        >
          <EditableLayerName />
          {isResponsive && (
            <MonitorSmartphoneIcon className="h-2 w-2 p-.25 rounded-sm text-white bg-red-500" />
          )}
        </div>
        {Boolean(layer.children?.length) && (
          <button onClick={layer.actions.toggleLayer}>{layer.expanded ? '▼' : '▶'}</button>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}

function copyNodeTree(rootNode: { rootNodeId: string; nodes: { [oldId: string]: Node } }) {
  const copiedRoot = cloneDeep(rootNode);
  const newIdMap: { [oldId: string]: string } = {};

  function generateNewId(oldId: string): string {
    const newId = nanoid(10);
    newIdMap[oldId] = newId;
    return newId;
  }

  // Generate new IDs for all nodes
  for (const oldId in copiedRoot.nodes) {
    const newId = generateNewId(oldId);
    copiedRoot.nodes[newId] = copiedRoot.nodes[oldId];
    delete copiedRoot.nodes[oldId];
    copiedRoot.nodes[newId].id = newId;
  }

  // Update references
  for (const newId in copiedRoot.nodes) {
    const node = copiedRoot.nodes[newId];
    if (node.data) {
      if (node.data.parent && newIdMap[node.data.parent]) {
        node.data.parent = newIdMap[node.data.parent];
      }
      if (node.data.nodes) {
        node.data.nodes = node.data.nodes.map(oldChildId => newIdMap[oldChildId] || oldChildId);
      }
    }
  }

  // Update rootNodeId
  copiedRoot.rootNodeId = newIdMap[copiedRoot.rootNodeId];

  return copiedRoot;
}

function safeStringifyWithLodash(obj: any, space: number | string = 2): string {
  let seen = new WeakSet();

  return JSON.stringify(
    obj,
    (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return '[Circular]';
        }
        seen.add(value);
        return _.cloneDeep(value);
      }
      return value;
    },
    space
  );
}
